export const CREATE_RFQ = `
mutation CreateRfq($amount: String!, $currency: String!) {
  createRfq(amount: $amount, currencyName: $currency) { 
    rfq 
    { 
      id
      txRef 
      email
      iban
      status
      buyRate
      sellRate
      sekDeposit
      eurRequest
      createdAt
      settledAt
      clientPid
      kycData
    } 
  }
}
`;

export const TOPUP_RFQ = `
mutation topupRfq ($amount: String!, $currency: String!, $status: String!, $txRef: String!) {
  topupRfq(amount: $amount, currencyName: $currency, status: $status, txRef: $txRef) { 
    status 
  } 
}`;

export const UPDATE_IBAN = `
mutation updateIBAN ($country: String!, $currency: String!, $iban: String!, $owner: String!) {
  updateIban(bankingCountry: $country, currencyName: $currency, iban: $iban, ownersName: $owner) { 
    success 
  }
}`;

export const WHITELIST_ADDRESS = `
mutation whitelistAddress ($address: String!, $label: String!) {
  whiteList(address: $address, label: $label) {
    success
  }
}`;

export const UPDATE_WEBHOOK = `mutation updateWebhook ($webhook: String!, $publicKey: String!) {
  updateWebhook(webhook: $webhook, publicKey: $publicKey) { 
    success 
  }
}`;

export const CREATE_CLIENT_PAYOUT = `mutation createClientPayout ($input: ClientPayoutInput!) {
  createClientPayout(paymentInfo: $input) { 
    name
    recipientType
    iban
    bic
    recipientBankCountry
    currency
    amount
    paymentReference
    recipientCountry
    addressLine1
    addressLine2
    city
    postalCode    
  }
}`;

export const CREATE_COUNTERPARTY = `mutation createCounterparty ($merchantId: String!, $input: CounterpartyPayoutInput!) {
  createCounterparty(merchantId: $merchantId, paymentInfo: $input) {
    counterpartyId
  }
}`;

export const CREATE_BATCHED_CLIENT_PAYOUT = `
mutation createBatchedClientPayout($paymentInfoList: [ClientPayoutInput]!) {
  createBatchedClientPayout (paymentInfoList: $paymentInfoList) {
    result {
      name
      recipientType
      iban
      bic
      recipientBankCountry
      currency
      amount
      paymentReference
      recipientCountry
      addressLine1
      addressLine2
      city
      postalCode
      error
    }
  } 
}
`;
export const ADD_COUNTERPARTY = `
mutation addCounterparty ($input: ClientPayoutInput!) {
  addCounterparty(clientPayoutData: $input) {
    clientPayout {
      name
      recipientType
      iban
      bic
      recipientBankCountry
      currency
      amount
      paymentReference
      recipientCountry
      addressLine1
      addressLine2
      city
      postalCode
    }
  }
}`;

export const CREATE_PAYIN = `
  mutation createPayin ($amount: String, $ccyName: String) {
    createPayin(amount: $amount, ccyName: $ccyName) {
      url
    }
  }
`;

export const INIT_PAYLINK = `
  mutation initPayLink (
    $bank: String, 
    $personId: String, 
    $authMode: String, 
    $txRef: String) {
    initPayLink(
      bank: $bank, 
      personId: $personId, 
      authMode: $authMode, 
      txRef: $txRef) {
      rfqId
      opId
    }
  }
`;

export const CONFIRM_ACCOUNT = `
mutation confirmAccount ($balance: String, $currency: String, $iban: String, $task: String) {
  confirmAccount(balance: $balance, currency: $currency, iban: $iban, task: $task) {
    status
  }
}
`;

export const REJECT_ACCOUNT = `
mutation rejectAccount ($task: String) {
  rejectAccount(task: $task) {
    status
  }
}
`;

export const CANCEL_PAYMENT = `
mutation cancelPay ($taskId: String, $txRef: String) {
  cancelPay(task: $taskId, ref: $txRef) {
    status
  }
}
`;

export const CHALLENGE_PI = `
mutation createPi ($amount: String, $currency: String, $clientId: String) {
  createPi(amount: $amount, currencyName: $currency, clientId: $clientId) {
    payment {
      id
    }
    challange
  }
}
`;

export const REVOKE_API_KEY = (kid: string) => `
{ 
    revokeApiKey(kid: "${kid}")
}
`;

export const REQUEST_KYC_APPROVAL = `
mutation requestKycApproval ($personId: String) {
  requestKycApproval(personId: $personId) {
    task
  }
}`;

export const UPDATE_MERCHANT_IBAN = `
mutation ($email: String!, $newIban: String!) {
  updateMerchant (email: $email, newIban: $newIban) {
    success, 
  }
}`;

export const VERIFY_LINK = `
mutation verifyLink ($personId: String) {
  verifyLink (personId: $personId) {
    verify 
  }
}`;

export const CREATE_BATCH = `
mutation createBatch ($startTime: DateTime!, $endTime: DateTime!) {
  createBatch(startTime: $startTime, endTime: $endTime) {
    success
    message
    batchId
  }
}`;

export const UPDATE_WITHDRAWAL_STATUS = `mutation updateWithdrawalStatus ($input: UpdateWithdrawalStatusInput!) {
  updateWithdrawalStatus(input: $input) { 
    success
    message
  }
}`;

export const BATCH_UPDATE_WITHDRAWAL_STATUSES = `mutation batchUpdateWithdrawalStatus ($input: [WithdrawStatusInput]!) {
  batchUpdateWithdrawalStatus(input: $input) { 
    success
    message
  }
}`;
